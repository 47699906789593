import classNames from 'classnames';
import MainLogoLg from './assets/main-logo-sm-title.png';

import React, { PropsWithChildren, useEffect, useState } from 'react';
import 'react-contexify/dist/ReactContexify.css';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useLocation } from 'react-use';
import 'reactjs-popup/dist/index.css';
import styles from './app.module.scss';
import { MainNav } from './components-v2/main-nav/main-nav';
import Box from './components-v2/utils';
import { getDefaultCode } from './components/main-menu';
import { NotFoundPage } from './components/page';
import { initLanguages, swicthLanguage } from './i18n';
import ClientListPage from './pages/administration/clients/client-list-page/client-list-page';
import ManageClientPage from './pages/administration/clients/manage-client-pageset/manage-client.page';
import PreviewClientStructurePage from './pages/administration/clients/manage-client-pageset/preview-client-structure.page';
import ProcessClientCreatePage from './pages/administration/clients/manage-client-pageset/process-client-create.page';
import ProcessClientImportRessourcesPage from './pages/administration/clients/manage-client-pageset/process-client-import-ressources.page';
import RoleListPage from './pages/administration/roles/role-list-page/role-list-page';
import UserListPage from './pages/administration/users/user-list-page/user-list-page';
import ChargingCardsListPage from './pages/charging-cards/charging-cards-list-page';
import FmChargingSessionsListPage from './pages/charging-sessions/fm-charging-sessions-list-page';
import LmChargingSessionsListPage from './pages/charging-sessions/lm-charging-sessions-list-page';
import LmFmChargingSessionsListPage from './pages/charging-sessions/lmfm-charging-sessions-list-page';
import ChargingStationsEnergyPage from './pages/charging-stations-overview/charging-stations-energy-page';
import ChargingStationsOverviewPage from './pages/charging-stations-overview/charging-stations-overview-page';
import ClusterChargePointsDetailPage from './pages/clusters/clusters-detail-chargepoints-page';
import ClusterStationsDetailPage from './pages/clusters/clusters-detail-stations-page';
import ClusterListPage from './pages/clusters/clusters-list-page';
import DeploymentOverviewPage from './pages/dev/deployments/deployment-overview.page';
import ComponentGalleryPage from './pages/dev/dev-component-gallery/component-gallery-page';
import DevOverviewPage from './pages/dev/dev-overview-page';
import DevToolsPage from './pages/dev/dev-tools-page';
import TranslationsPage from './pages/dev/dev-translations-page';
import DevVersionsPage from './pages/dev/dev-versions-page';
import LoginPage from './pages/login-page';
import LogoutPage from './pages/logout-page';
import EvMigrationJobDetailsPage from './pages/operations/ev-migration/ev-migration-job-details-page';
import EvMigrationPage from './pages/operations/ev-migration/ev-migration-page';
import InteractionsLogsPage from './pages/operations/interactions-logs/interactions-logs-page';
import OperationsPage from './pages/operations/operations-page';
import OrgStationsListPage from './pages/organisation/stations/org-stations-list-page';
import StructureOverviewPage from './pages/organisation/structure/structure-overview-page/structure-overview-page';
import OrgUsersListPage from './pages/organisation/users/users-list-page/org-users-list-page';
import ChargepointsListPage from './pages/stations/chargepoints-list-page';
import StationsListPage from './pages/stations/stations-list-page';
import StatusLogPage from './pages/status-log/status-log-page';
import SystemAdminListPage from './pages/system/admin/admin-list-page/admin-list-page';
import AffiliateListPage from './pages/system/affiliates/affiliate-list-page/affiliate-list-page';
import { useAuth } from './utils/AuthProvider';
import UserPreferencesProvider, { useUserPreferences } from './utils/UsePreferenceProvider';
import useEffectAsync from './utils/useEffectAsync';

class ErrorBoundary extends React.Component {
  // issues w. browser plugins see here:
  // https://github.com/facebook/react/issues/11538
  // the browser translate page plugin usually cause issues.
  constructor(props: PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: any, info: any) {
    console.error('react error', error, 'info', info);
  }

  render() {
    if ((this.state as any).hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            color: 'red',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
          }}
        >
          <h1>Unexpected Error</h1>
          <p>
            This can be caused by browser plugins.
            <br />
            Please deactivate all plugins including the translation plugin of you browser.
          </p>
        </div>
      );
    }

    return (this.props as any).children;
  }
}

function App() {
  let { user } = useAuth();
  let [defaultPage, setDefaultPage] = useState('');
  const localPrefs = useUserPreferences();
  let [initDone, setInitDone] = useState(false);
  const location = useLocation();

  useEffectAsync(async () => {
    if (!user) {
      await initLanguages();
      setInitDone(true);
      return;
    }
    try {
      await swicthLanguage(user.preferences.languageCode || 'en');
      // await initLanguages();
    } catch (e) {
      console.error('failed init languages ...');
    }

    setInitDone(true);
  }, [user]);

  useEffect(() => {
    if (user) {
      return;
    }
    setDefaultPage('');
  }, [user]);

  useEffect(() => {
    if (!user) return;
    const views = user.preferences.views;

    const infrastructureCode = getDefaultCode(views.infrastructure.clients, localPrefs.clientCode);
    const cardsCode = getDefaultCode(views.cards.clients, localPrefs.clientCode);
    const locationsCode = getDefaultCode(views.locations.clients, localPrefs.clientCode);
    const sessionsCode = getDefaultCode(views.sessions.clients, localPrefs.clientCode);
    const clusterCode = getDefaultCode(views.clusters.clients, localPrefs.clientCode);
    const orgStructureCode = getDefaultCode(views.organization.structure?.clients, localPrefs.clientCode);
    const orgUsersCode = getDefaultCode(views.organization.users?.clients, localPrefs.clientCode);
    const adminClientsCode = getDefaultCode(views.administration.clients?.affiliates, localPrefs.affiliateCode);
    const adminRolesCode = getDefaultCode(views.administration.roles?.affiliates, localPrefs.affiliateCode);
    const adminUsersCode = getDefaultCode(views.administration.users?.affiliates, localPrefs.affiliateCode);
    if (views.locations.visible && locationsCode) {
      setDefaultPage(`/locations/status/${locationsCode}`);
    } else if (views.infrastructure.visible && infrastructureCode) {
      setDefaultPage(`/infrastructure/stations/${infrastructureCode}`);
    } else if (views.cards.visible && cardsCode) {
      setDefaultPage(`/charging-cards/${cardsCode}`);
    } else if (views.sessions.visible && sessionsCode) {
      setDefaultPage(`/${views.sessions.prefix}-charging-sessions/${sessionsCode}`);
    } else if (views.clusters.visible && clusterCode) {
      setDefaultPage(`/loadmanagement/${clusterCode}`);
    } else if (views.organization.structure?.visible && orgStructureCode) {
      setDefaultPage(`/organisation/structure/${orgStructureCode}`);
    } else if (views.organization.users?.visible && orgUsersCode) {
      setDefaultPage(`/organisation/users/${orgUsersCode}`);
    } else if (views.administration.clients?.visible && adminClientsCode) {
      setDefaultPage(`/administration/clients/${adminClientsCode}`);
    } else if (views.administration.roles?.visible && adminRolesCode) {
      setDefaultPage(`/administration/roles/${adminRolesCode}`);
    } else if (views.administration.users?.visible && adminUsersCode) {
      setDefaultPage(`/administration/users/${adminUsersCode}`);
    } else if (views.system.affiliates) {
      setDefaultPage(`/system/affiliates`);
    } else if (views.system.users) {
      setDefaultPage(`/system/users`);
    } else if (views.operations.visible) {
      setDefaultPage(`/operations`);
    } else {
      setDefaultPage('/not-found');
    }
  }, [user, localPrefs.clientCode, localPrefs.affiliateCode]);

  if (!initDone) {
    return <div>loading...</div>;
  }

  if (user && defaultPage) {
    const sessionUrlPrefix = user.preferences.views.sessions.prefix;
    // const sessionUrlPrefix = 'lm' as string;
    const sessionElement =
      sessionUrlPrefix === 'lmfm' ? <LmFmChargingSessionsListPage /> : sessionUrlPrefix === 'fm' ? <FmChargingSessionsListPage /> : <LmChargingSessionsListPage />;
    return (
      <ErrorBoundary>
        <div className={classNames(styles.root)}>
          <Router>
            <UserPreferencesProvider>
              <MainNav />
              <main className={classNames(styles.main)}>
                <div className={classNames(styles.mainInner)}>
                  <Box className={styles.header}>
                    <Box className={styles.headerLogo}>
                      <img alt='Charging Solution Platform' src={MainLogoLg} />
                    </Box>
                  </Box>
                  <Routes>
                    <Route path='/' element={<Navigate to={defaultPage} />} />
                    <Route path='/loadmanagement/:clientCode' element={<ClusterListPage />} />
                    <Route path='/loadmanagement/:clientCode/cluster-stations/:clusterId' element={<ClusterStationsDetailPage />} />
                    <Route path='/loadmanagement/:clientCode/cluster-chargepoints/:clusterId' element={<ClusterChargePointsDetailPage />} />

                    <Route path='/infrastructure/stations/:clientCode' element={<StationsListPage />} />

                    <Route path='/infrastructure/chargepoints/:clientCode' element={<ChargepointsListPage />} />

                    <Route path='/charging-cards/:clientCode' element={<ChargingCardsListPage />} />

                    {/* <Route path="/clients" element={<ClientsListPage />} /> */}
                    {/* <Route path="/clients/:clientCode" element={< />} /> */}

                    <Route path={`/${sessionUrlPrefix}-charging-sessions/`} element={sessionElement} />
                    <Route path={`/${sessionUrlPrefix}-charging-sessions/:clientCode`} element={sessionElement} />

                    <Route path='/locations' element={<ChargingStationsOverviewPage />} />
                    <Route path='/locations/status' element={<ChargingStationsOverviewPage />} />

                    <Route path='/locations/status/:clientCode' element={<ChargingStationsOverviewPage />} />
                    <Route path='/locations/energy/:clientCode' element={<ChargingStationsEnergyPage />} />

                    <Route path='/status-log/:clientCode' element={<StatusLogPage />} />

                    <Route path='/administration/clients/:affiliateCode' element={<ClientListPage />} />
                    <Route path='/administration/clients/:affiliateCode/manage/:clientCode?' element={<ManageClientPage />} />
                    <Route path='/administration/clients/:affiliateCode/structure-import' element={<PreviewClientStructurePage />} />
                    <Route path='/administration/clients/:affiliateCode/create' element={<ProcessClientCreatePage />} />
                    <Route path='/administration/clients/:affiliateCode/import-resources/:clientCode' element={<ProcessClientImportRessourcesPage />} />
                    <Route path='/administration/roles/:affiliateCode' element={<RoleListPage />} />
                    <Route path='/administration/users/:affiliateCode' element={<UserListPage />} />

                    <Route path='/system/affiliates' element={<AffiliateListPage />} />
                    <Route path='/system/admins' element={<SystemAdminListPage />} />

                    <Route path='/organisation/structure/:clientCode' element={<StructureOverviewPage />} />
                    <Route path='/organisation/stations/:clientCode' element={<OrgStationsListPage />} />
                    <Route path='/organisation/users/:clientCode' element={<OrgUsersListPage />} />

                    <Route path='/dev' element={<DevOverviewPage />} />

                    <Route path='/dev/translations' element={<TranslationsPage />} />
                    <Route path='/dev/versions' element={<DevVersionsPage />} />
                    <Route path='/dev/tools' element={<DevToolsPage />} />
                    <Route path='/dev/gallery' element={<ComponentGalleryPage />} />
                    <Route path='/dev/deployments' element={<DeploymentOverviewPage />} />

                    <Route path='/operations' element={<OperationsPage />} />
                    <Route path='/operations/interactions-logs' element={<InteractionsLogsPage />} />
                    <Route path='/operations/ev-migration' element={<EvMigrationPage />} />
                    <Route path='/operations/ev-migration/:jobId' element={<EvMigrationJobDetailsPage />} />

                    <Route path={'*'} element={<NotFoundPage />} />
                  </Routes>
                </div>
              </main>
            </UserPreferencesProvider>
          </Router>
        </div>
      </ErrorBoundary>
    );
  } else if (location.pathname === '/logout') {
    return (
      <div className={classNames(styles.root)}>
        <main className={classNames(styles.main, styles.mainNoMenu)}>
          <LogoutPage />
        </main>
      </div>
    );
  } else {
    // console.log('render login');
    return (
      <div className={classNames(styles.root)}>
        <main className={classNames(styles.main, styles.mainNoMenu)}>
          <LoginPage />
        </main>
      </div>
    );
  }
}

export default App;
