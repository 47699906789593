import classNames from 'classnames';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Popover } from 'react-tiny-popover';
import { ReactComponent as CalendarIco } from '../assets/calendar2.svg';
import { FormSeparator } from '../components/page-layout';
import { useAuth } from '../utils/AuthProvider';
import { formatter } from '../utils/localized-types';
import useBetterTranslate from '../utils/translation-utils';
import ButtonV2 from './button';
import styles from './date-range.module.scss';
import InputV2 from './input';
import Box from './utils';
const DATE_FORMAT = 'YYYY-MM-DD';

export interface Range {
  startDate: string;
  endDate: string;
}

export interface DateRangeProps {
  className?: string;
  range?: Range;
  maxDate?: Date;
  minDate?: Date;
  onChange?: (range: Range) => void;
}

export function DateRangeCalendar(props: DateRangeProps) {
  const maxD = props.maxDate ? moment(props.maxDate).startOf('D') : moment().add(1, 'd');
  const minD = props.minDate ? moment(props.minDate).startOf('D') : moment('2000-12-31');
  const ranges = props.range ? [{ key: 'selection', startDate: moment(props.range.startDate).toDate(), endDate: moment(props.range.endDate).toDate() }] : [];

  return (
    <div data-cy='date-range-calendar' className={classNames(styles.dateRangeCalendar, props.className)}>
      <DateRange
        ranges={ranges}
        weekStartsOn={1}
        maxDate={maxD.toDate()}
        minDate={minD.toDate()}
        // navigatorRenderer={() => <>foo</>}
        disabledDay={(d) => {
          if (moment(d).diff(maxD, 'days') > 0) return true;
          if (moment(d).diff(minD, 'days') < 0) return true;
          return false;
        }}
        weekdayDisplayFormat='EEEEEE'
        showDateDisplay={false}
        // showMonthArrow={false}
        // dayContentRenderer={() => <></>}
        // showMonthAndYearPickers={false}
        // editableDateInputs={false}
        // showPreview={false}
        // showSelectionPreview={true}
        // moveRangeOnFirstSelection={false}
        direction='horizontal'
        months={2}
        onChange={(v) => {
          const range = (v as any).selection;
          props.onChange?.({ startDate: moment(range.startDate).format(DATE_FORMAT), endDate: moment(range.endDate).format(DATE_FORMAT) });
        }}
      />
    </div>
  );
}

export interface DateRangeDisplayProps {
  className?: string;
  range?: Range;
  onClick?: () => void;
}
export function DateRangeDisplay(props: DateRangeDisplayProps) {
  const { _t } = useBetterTranslate('date-range');
  const { user } = useAuth();
  const languageCode = user?.preferences.languageCode;
  const formattedFrom = props.range?.startDate ? formatter.formatDate(moment(props.range.startDate).toDate(), languageCode) : '';
  const formattedTo = props.range?.endDate ? formatter.formatDate(moment(props.range.endDate).toDate(), languageCode) : '';

  return (
    <Box onClick={props.onClick} testId='date-range-display' kind={'hflex'} className={classNames(styles.dateRangeDisplay, props.className)}>
      <Box kind={'hflex'} gap='l' className={styles.inputsWrapper}>
        <label className={styles.fromWrapper}>
          <span>{_t('From')}</span>
          <InputV2 value={formattedFrom} type='text' />
        </label>
        <label className={styles.toWrapper}>
          <span>{_t('To')}</span>
          <InputV2 value={formattedTo} type='text' />
        </label>
      </Box>

      <ButtonV2 apperance='icon' className={styles.btn}>
        <CalendarIco />
      </ButtonV2>
    </Box>
  );
}

export interface DateRangeChooserProps {
  className?: string;
  range?: Range;
  maxDate?: Date;
  minDate?: Date;
  customRender?: (calendar: ReactNode) => ReactNode;
  onChange?: (range?: Range) => void;
}
export function DateRangeChooser(props: DateRangeChooserProps) {
  const { _t } = useBetterTranslate('date-range');

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [range, setRange] = useState(props.range);

  const onWantAccept = () => {
    props.onChange?.(range);
    setPopoverOpen(false);
  };
  const onWantCancel = () => {
    setRange(props.range);
    setPopoverOpen(false);
  };

  const calendar = <DateRangeCalendar maxDate={props.maxDate} minDate={props.minDate} onChange={(range) => setRange(range)} range={range} />;
  const content = props.customRender ? (
    props.customRender(calendar)
  ) : (
    <>
      <Box kind={'vflex'} gap='m' className={styles.popoverContainer}>
        {calendar}
        <FormSeparator />
        <Box kind={'hflex'} justify='center' gap='l' className={styles.popoverFooter}>
          <ButtonV2 dataCy='reset-date-range' onClick={onWantCancel} apperance='secondary'>
            {_t('Reset')}
          </ButtonV2>
          <ButtonV2 dataCy='accept-date-range' onClick={onWantAccept} apperance='primary'>
            {_t('Apply')}
          </ButtonV2>
        </Box>
      </Box>
    </>
  );

  return (
    <Popover
      isOpen={popoverOpen}
      content={<>{content}</>}
      containerStyle={{ zIndex: '1000' }}
      align={'start'}
      onClickOutside={onWantCancel}
      positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
    >
      <div>
        <DateRangeDisplay
          onClick={() => {
            if (popoverOpen) onWantCancel();
            else setPopoverOpen(true);
          }}
          range={range}
        />
      </div>
    </Popover>
  );
}

// export function PopoverContainer(props: PropsWithChildren<{ className?: string }>) {

//   return (
//     <div className={styles.popoverContainer}>
//       {props.children}
//     </div>
//   )
// }
