import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button';
import Input, { ErrorLabel, FormContainer } from '../../../../components/input';
import CheckBox from '../../../../components/input-check-box';
import { FormRow, PageBody, PageDesc, PageFooter, PageLayout, PageTitle, Spacer, formGrid } from '../../../../components/page-layout';
import { replaceHistoryState, useLocationState } from '../../../../hooks/useBetterNavigate';
import useFormState, { formValidator } from '../../../../hooks/useFormState';
import { usePageParams } from '../../../../hooks/usePageParams';
import api from '../../../../services/api';
import { ClientCpmsIdCheck, ClientDto, ClientIndividualRoleDto, RoleType } from '../../../../services/api-client/csp-api';
import useBetterTranslate from '../../../../utils/translation-utils';
import useEffectAsync from '../../../../utils/useEffectAsync';
import styles from './manage-client.page.module.scss';
import { PermissionsGroupsOfRole, getPermissionGroupStructure } from '../../roles/role-create-popup/permissions-groups';
import { ReactComponent as InfoIco } from '../../../../assets/info.svg';
import RolePermissionsPopup from '../../../../components/role-permissions-popup';

interface RoleItem {
  id: string;
  name: string;
  selected: boolean;
  type: RoleType;
}
export interface ManageClientPageProps {
  className?: string;
}

type FilterParameters = {
  clientCode?: string;
  affiliateCode?: string;
};

export default function ManageClientPage(props: ManageClientPageProps) {
  const { _t } = useBetterTranslate(`manage-client-page`);
  const navigate = useNavigate();
  const clientFromState = useLocationState<ClientDto>('client', { default: undefined });

  const [filterParams] = usePageParams<FilterParameters>({}, {});

  const emptyClient: ClientDto = { affiliateCode: filterParams.affiliateCode || '', code: '', standardRoleIds: [], title: '', cpmsId: '', individualRoles: [] };
  const [clientDto, setClientDto] = useState<ClientDto>(clientFromState || emptyClient);

  const { affiliateCode, clientCode } = filterParams;

  const isNew = filterParams.clientCode === undefined;

  const formState = useFormState();
  const [titleField, setTitle] = formState.useInputState({
    label: _t('Kundenname'),
    placeholder: _t('Firmennamen hinzufügen'),
    maxLength: 100,

    initialValue: '',
    validate: async (val) => {
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('Dieses Feld darf nicht leer sein.')))) return err;
      if ((err = formValidator.maxLen(100, val, _t('Kundenname darf nicht länger als 100 Zeichen sein.')))) return err;
      if (clientDto.title === val) return;

      const exist = await api.client.titleExists(filterParams.affiliateCode || '', { title: val });
      if (exist.data) return _t(`Kundenname ist bereits vergeben.`);
    },
  });
  const [codeField, setCode] = formState.useInputState({
    label: _t('Code'),
    labelInfo: _t('Der Code wird als eindeutige Kennung im System verwendet.'),
    dataCy: 'infCode',
    placeholder: _t('Code'),
    maxLength: 10,

    initialValue: '',
    validate: async (val) => {
      if (!isNew) return;
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('Dieses Feld darf nicht leer sein.')))) return err;
      if ((err = formValidator.maxLen(100, val, _t('Code darf nicht länger als 10 Zeichen sein.')))) return err;
      if (val === 'ROOT') return _t(`Code ist bereits vergeben.`); //reserved word

      const code = `${affiliateCode}-${val}`;
      const exist = await api.client.codeExists(affiliateCode!, { code });
      if (exist.data) return _t(`Code ist bereits vergeben.`);
    },
    transform: (val) => {
      if (!val) return val;
      return val.replace(/[^a-z0-9]/gi, '').toUpperCase();
    },
  });

  const [cpmsField, setCpms] = formState.useInputState({
    label: _t('Unternehmen-ID'),
    labelInfo: _t('Unternehmen-ID muss die korrekte Firmen-ID von HTB oder die Org-Id von EVP sein, um die richtige Hierarchie zu übertragen.'),
    dataCy: 'infCompanyId',
    placeholder: _t('Unternehmen-ID'),
    maxLength: 50,

    initialValue: '',
    validate: async (val) => {
      let err: string | undefined = '';
      if ((err = formValidator.notEmpty(val, _t('Dieses Feld darf nicht leer sein.')))) return err;
      const response = await api.client.cpmsIdValidate(affiliateCode!, { cpmsId: val });
      if (response.data.result === ClientCpmsIdCheck.ALREADY_USED_BY_ANOTHER_CLIENT) {
        return _t(`Unternehmen-ID ist bereits vergeben.`);
      } else if (response.data.result === ClientCpmsIdCheck.NOT_FOUND_AT_ANY_CPMS) {
        return _t(`Unternehmen-ID ist weder in HTB noch in EVP vorhanden.`);
      }
    },
    transform: (val) => {
      if (!val) return val;
      return val.trim();
    },
  });

  //roles
  const [stdRolesField, setStdRoles] = formState.useFieldState<RoleItem[]>({
    label: _t('Rollen'),
    labelInfo: _t('Wählen Sie die Rollen, die Sie diesem Unternehmen hinzufügen möchten.'),
    dataCy: 'infRoles',
    initialValue: [],
    validate: async (val) => {
      if (indRoles.length === 0 && !val.find((item) => item.selected)) {
        if (!isNew) {
          return _t('Um die Änderung am Unternehmen zu speichern, müssen Sie mindestens eine Rolle auswählen.');
        }
        return _t('Um das Unternehmen zu erstellen, müssen Sie mindestens eine Rolle auswählen.');
      }
    },
  });
  const [unselectedStdRolesField, setUnselectedStdRolesField] = formState.useFieldState<string[]>({
    label: '',
    initialValue: [],
  });

  const [indRoles, setIndRoles] = useState<ClientIndividualRoleDto[]>([]);

  const toggleRoleItem = (role: RoleItem) => {
    role.selected = !role.selected;
    if (role.selected) {
      setUnselectedStdRolesField(unselectedStdRolesField.value.filter((item) => item !== role.id));
    } else if (clientDto.standardRoleIds.find((r) => r === role.id)) {
      setUnselectedStdRolesField([...unselectedStdRolesField.value, role.id]);
    }

    stdRolesField.onChange([...stdRolesField.value]);
  };

  useEffect(() => {
    if (!clientDto) return;
    setTitle(clientDto.title);
    let code = clientDto.code || '';
    // remove affiliate from code
    if (code.indexOf(`-`) >= 0) {
      code = code.split(`-`)[1];
    }
    setCode(code);
    setCpms(clientDto.cpmsId || '');
    setIndRoles(clientDto.individualRoles || []);

    // setIndRoles(clientObj.individualRoles || []);
  }, [clientDto, setTitle, setCode, setCpms]);

  useEffectAsync(async () => {
    if (!affiliateCode) return;

    const res = await api.client.listRoles({ type: RoleType.Standard, affiliateCode: affiliateCode });
    const roleItems: RoleItem[] = res.data.map((role) => {
      return { id: role.id || '', name: role.name, selected: false, type: role.type };
    });

    let client = clientDto;
    if (clientCode) {
      const clientObj = (await api.client.get(affiliateCode!, clientCode)).data;
      client = clientObj;
      setClientDto(clientObj);
    }

    for (const roleItem of roleItems) {
      if (client.standardRoleIds.includes(roleItem.id)) {
        roleItem.selected = true;
      }
    }

    setStdRoles(roleItems);
  }, [affiliateCode, clientCode]);

  const [currentPermissionGroupOfRole, setCurrentPermissionGroupOfRole] = useState<PermissionsGroupsOfRole>();
  const onRolesPermissionsClick = async (role: RoleItem) => {
    const res = await api.client.getPermissionsOfRole(filterParams.affiliateCode || '', role.id);
    const permissionGroups = getPermissionGroupStructure(res.data.permissions);
    setCurrentPermissionGroupOfRole({ roleId: role.id || '', roleName: role.name || '', roleType: role.type as RoleType, permissionsGroups: permissionGroups });
  };

  formState.handleSubmit(async () => {
    if (!affiliateCode) return;

    const standardRoleIds: string[] = [];
    stdRolesField.value?.forEach((item) => {
      if (item.selected) {
        standardRoleIds.push(item.id);
      }
    });
    const code = clientCode || `${affiliateCode}-${codeField.value}`;
    let client = {
      title: titleField.value.trim(),
      code,
      affiliateCode: affiliateCode,
      cpmsId: cpmsField.value,
      standardRoleIds,
      individualRoles: indRoles,
    };
    if (clientCode) {
      // await api.client.update(client);
    } else {
      // store in state
      replaceHistoryState({ client });
      // navigate than
      navigate(`/administration/clients/${affiliateCode}/structure-import`, { state: { client } });
    }

    // console.log('SUBMIT')
  });

  // if (err) return <>ERROR</>;

  return (
    <PageLayout className={classNames(styles.root, props.className)}>
      {/* <ApiBasedContent resp={{}} err={err} fetching={fetching}> */}
      {/* {() => ( */}
      {/* <> */}
      <PageTitle>{isNew ? _t(`Create Client`) : _t(`Edit Client`)}</PageTitle>
      <PageDesc>{isNew ? _t(`Please type in all information to create the client.`) : _t(`Change this Client`)}</PageDesc>
      <PageBody>
        <FormRow>
          <Input v2={true} {...titleField} className={classNames(formGrid.w1)} />

          {isNew && (
            <FormContainer label={codeField.label} validationErr={codeField.validationErr} className={classNames(formGrid.w1)}>
              <span>{affiliateCode}-</span>
              <Input v2={true} {...codeField} label='' validationErr={null} />
            </FormContainer>
          )}
          {!isNew && (
            <FormContainer label={codeField.label} validationErr={codeField.validationErr} className={classNames(formGrid.w1)}>
              <Input v2={true} {...codeField} label='' disabled={true} validationErr={null} />
            </FormContainer>
          )}

          <Spacer w='1' />
        </FormRow>
        <FormRow>
          <Input v2={true} {...cpmsField} className={classNames(formGrid.w1)} />
          <Spacer w='2' />
        </FormRow>

        <FormRow>
          <FormContainer label={stdRolesField.label} renderMode='span'>
            <div className={styles.rolesWrapper} data-cy={'lstRoles'}>
              <div className={classNames(styles.roles, styles.standard, stdRolesField.validationErr ? styles.hasValidationError : '')}>
                {stdRolesField.value.map((role, idx) => {
                  return (
                    <CheckBox
                      key={idx}
                      className={unselectedStdRolesField.value.includes(role.id) ? styles.warning : ''}
                      label={
                        <>
                          <span>{role.name}</span>
                          <span className={styles.infoIco}>
                            <InfoIco
                              onClick={async () => {
                                await onRolesPermissionsClick(role);
                              }}
                            ></InfoIco>
                          </span>
                        </>
                      }
                      isSelected={role.selected}
                      onChange={() => {
                        toggleRoleItem(role);
                      }}
                      preventDefaultToClickLabel={true}
                    />
                  );
                })}
              </div>
              <div className={styles.rolesMessages} data-cy='ermRoles'>
                <div className={styles.warning} data-cy='wamRolePermission'>
                  {unselectedStdRolesField.value.length > 0 ? '\u26A0 ' + _t('Die Rolle wird allen Usern entzogen, die diese Rolle besitzen.') : ' '}
                </div>

                <ErrorLabel showWarnSign={true}>{stdRolesField.validationErr}</ErrorLabel>
              </div>
            </div>
          </FormContainer>
        </FormRow>
      </PageBody>
      <PageFooter>
        {isNew && <Button onClick={() => formState.submit()}>{_t(`Create Client`)}</Button>}
        {!isNew && <Button>{_t(`Save Client`)}</Button>}
      </PageFooter>

      {/* </> */}
      {/* )} */}
      {/* </ApiBasedContent> */}

      {/* if (fetching) return <LoadingSpinner />;
        return (
          <>

          </>
        ); */}
      <RolePermissionsPopup
        open={!!currentPermissionGroupOfRole?.roleId}
        permissionsGroups={currentPermissionGroupOfRole?.permissionsGroups || []}
        onClose={() => {
          setCurrentPermissionGroupOfRole(undefined);
        }}
        roleType={currentPermissionGroupOfRole?.roleType}
        roleName={currentPermissionGroupOfRole?.roleName}
      />
    </PageLayout>
  );
}
