import ContentLoader from 'react-content-loader';

import classNames from 'classnames';
import _ from 'lodash';
import { CSSProperties, Fragment, PropsWithChildren, ReactNode } from 'react';
import styles from './utils.module.scss';

export type Colors =
  | 'brand-neutral-900'
  | 'brand-neutral-300'
  | 'brand-neutral-200'
  | 'brand-neutral-100'
  | 'brand-neutral-050'
  | 'brand-neutral-050'
  | 'brand-neutral-000'
  | 'brand-neutral-150'
  | 'brand-neutral-400'
  | 'brand-neutral-700'
  | 'brand-neutral-1000'
  | 'brand-neutral-125'
  | 'brand-neutral-250'
  | 'primary-neutral-100'
  | 'primary-neutral-090'
  | 'primary-neutral-085'
  | 'primary-neutral-080'
  | 'primary-neutral-060'
  | 'primary-neutral-050'
  | 'primary-neutral-030'
  | 'primary-neutral-020'
  | 'primary-neutral-010'
  | 'primary-white'
  | 'primary-500'
  | 'atenso-blue'
  | 'secondary-teal'
  | 'blue-blue-jeans'
  | 'dataviz-brown-naples-yellow'
  | 'dataviz-blue-pale-cyan'
  | 'red-light-salmon'
  | 'functional-error-full'
  | 'functional-validation-full'
  | 'functional-disabled-200'
  | 'green-kelly-green'
  | 'dataviz-green-light-green'
  | 'dataviz-blue-medium-persian'
  | 'brown-gold-metallic'
  | 'transparent';
// | 'danger' | 'warn' | 'success' | 'info';

export type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type Spaces2 = '0' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | 'auto';
export type Spaces = 'auto' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
export type CellSize = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
export type _FontSizes = 'default' | '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs';
export type FontSizes = _FontSizes | `${Breakpoints}:${_FontSizes}`;
export type LineHeights = 'default' | '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs' | '3xs';
export type FontWeights = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800';
export type _Kind = 'vgrid' | 'hgrid' | 'cell' | 'vflex' | 'hflex';
export type Kind = _Kind | `${Breakpoints}:${_Kind}`;
export function coloToVar(color: Colors) {
  return `var(--col-${color})`;
}
export function contrastColor(color: Colors): Colors {
  const light: Colors = 'primary-white';
  const dark: Colors = 'primary-neutral-100';

  if (color === 'secondary-teal') return light;
  else if (color === 'dataviz-blue-medium-persian') return light;

  return dark;
}
export interface BoxProps {
  className?: string;
  kind?: Kind | Kind[];
  gap?: Spaces;
  cellSize?: CellSize;
  justify?: 'center' | 'space-around' | 'space-between' | 'space-evenly' | 'stretch' | 'flex-start' | 'flex-end';
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
  txtAlign?: 'center';
  flexGrow?: '1' | '2' | '3';
  tag?: keyof JSX.IntrinsicElements;
  fs?: FontSizes | FontSizes[];
  lh?: LineHeights;
  fw?: FontWeights;
  testId?: string;
  bg?: Colors;
  fg?: Colors;
  pad?: Spaces2 | Spaces2[];
  tooltip?: string;
  // border color
  bc?: Colors;
  radius?: string;
  w?: 'fit-content' | 'auto' | '100%';
  h?: 'fit-content' | 'auto' | '100%';
  ellipses?: boolean;
  txtWrap?: boolean;
  underline?: boolean;
  hover?: ('pointer' | 'underline')[];
  onClick?: () => void;
  styles?: CSSProperties;
}

export default function Box(props: PropsWithChildren<BoxProps>) {
  const classes: Record<string, boolean> = {};
  if (props.gap) {
    classes[styles[`gap-${props.gap}`]] = true;
  }
  if (props.txtAlign) {
    classes[styles[`txt-${props.txtAlign}`]] = true;
  }
  if (props.hover) {
    for (const behavior of props.hover) {
      classes[styles[`hover-${behavior}`]] = true;
    }
  }
  classes[styles['ellipses']] = !!props.ellipses;
  classes[styles['underline']] = !!props.underline;

  if (props.kind) {
    const items = _.isArray(props.kind) ? props.kind : [props.kind];
    for (const item of items) {
      const name = item.replace(':', '-resp-');
      classes[styles[`${name}`]] = true;
    }
  }
  if (props.txtWrap) {
    classes[styles[`txt-wrap`]] = true;
  }

  if (props.cellSize) {
    classes[styles[`cell-${props.cellSize}`]] = true;
  }
  if (props.fw) {
    classes[styles[`fw-${props.fw}`]] = true;
  }
  if (props.justify) {
    classes[styles[`justify-${props.justify}`]] = true;
  }
  if (props.align) {
    classes[styles[`align-${props.align}`]] = true;
  }
  if (props.flexGrow) {
    classes[styles[`flex-grow-${props.flexGrow}`]] = true;
  }
  if (props.onClick) {
    classes[styles['pointer']] = true;
  }
  if (props.fs) {
    const items = _.isArray(props.fs) ? props.fs : [props.fs];
    for (const fs of items) {
      const name = fs.replace(':', '-resp-');
      classes[styles[`fs-${name}`]] = true;
    }
  }
  if (props.lh) {
    classes[styles[`lh-${props.lh}`]] = true;
  }

  const allDataAttr = Object.entries(props).filter(([k, v]) => k.startsWith('data-'));
  const dataRec: Record<string, any> = {};
  for (const [k, v] of allDataAttr) {
    dataRec[k] = v;
  }

  const customStyles: CSSProperties = props.styles || {};
  if (props.bg) {
    customStyles.backgroundColor = coloToVar(props.bg);
  }
  if (props.fg) {
    customStyles.color = coloToVar(props.fg);
  }

  if (props.bc) {
    customStyles.borderColor = coloToVar(props.bc);
    customStyles.borderWidth = '1px';
    customStyles.borderStyle = 'solid';
  }
  if (props.radius) {
    if (!props.radius.endsWith('px')) {
      customStyles.borderRadius = `${props.radius}px`;
    } else {
      customStyles.borderRadius = props.radius;
    }
  }
  if (props.w) {
    customStyles.width = props.w;
  }
  if (props.h) {
    customStyles.height = props.h;
  }
  if (props.pad) {
    let pad = Array.isArray(props.pad) ? props.pad : [props.pad];
    const val = pad.map((p) => `var(--space-${p})`).join(' ');
    customStyles.padding = val;
  }

  const { tag: Wrapper = 'div' } = props;
  return (
    <Wrapper title={props.tooltip} {...dataRec} onClick={props.onClick} data-cy={props.testId} className={classNames(styles.box, props.className, classes)} style={customStyles}>
      {props.children}
    </Wrapper>
  );
}

export interface DividerProps {
  className?: string;
  kind?: 'h' | 'v';
  color?: Colors;
}

export function Divider(props: DividerProps) {
  const classes: Record<string, boolean> = {};
  const kind = props.kind || 'v';
  classes[styles[kind]] = true;

  return <div style={{ backgroundColor: coloToVar(props.color || 'primary-neutral-050') }} className={classNames(styles.divider, props.className, classes)}></div>;
}

export const StackedSkeleton = (props: { times?: number; skeleton: () => ReactNode }) => {
  const items: ReactNode[] = [];
  for (let i = 0; i < (props.times || 10); i++) {
    items.push(<Fragment key={i}>{props.skeleton()}</Fragment>);
  }

  return (
    <Box kind='vflex' gap='s'>
      {items}
    </Box>
  );
};

export const TableRowSkeleton = () => {
  const height = 110;
  return (
    <div className={classNames(styles.skeleton, styles.accordion)}>
      <ContentLoader
        height={height}
        speed={1}
        // backgroundColor={'#333'}
        // foregroundColor={'#999'}
        // viewBox="0 0 380 70"
      >
        <rect x='28' y='20' rx='2' ry='2' width='200' height='20' />
        <rect x='28' y='50' rx='2' ry='2' width='230' height='20' />

        <rect x='308' y='20' rx='2' ry='2' width='200' height='20' />
        <rect x='308' y='50' rx='2' ry='2' width='230' height='20' />

        <rect x='588' y='20' rx='2' ry='2' width='200' height='20' />
        <rect x='588' y='50' rx='2' ry='2' width='230' height='20' />

        <rect x='868' y='20' rx='2' ry='2' width='200' height='20' />
        <rect x='868' y='50' rx='2' ry='2' width='230' height='20' />
      </ContentLoader>
    </div>
  );
};

export const HugeListItemSkeleton = () => {
  const height = 110;
  return (
    <div className={classNames(styles.skeleton, styles.accordion)}>
      <ContentLoader
        height={height}
        speed={1}
        // backgroundColor={'#333'}
        // foregroundColor={'#999'}
        // viewBox="0 0 380 70"
      >
        <rect x='28' y='20' rx='2' ry='2' width='200' height='20' />
        <rect x='28' y='50' rx='2' ry='2' width='230' height='20' />
        <rect x='28' y='80' rx='2' ry='2' width='250' height='20' />

        <rect x='388' y='20' rx='2' ry='2' width='280' height='10' />
        <rect x='388' y='40' rx='2' ry='2' width='230' height='10' />
        <rect x='388' y='60' rx='2' ry='2' width='250' height='10' />
        <rect x='388' y='80' rx='2' ry='2' width='250' height='10' />

        <rect x='788' y='20' rx='2' ry='2' width='200' height='20' />
        <rect x='788' y='50' rx='2' ry='2' width='300' height='50' />

        <rect x='1200' y='20' rx='20' ry='20' width='100' height='50' />
      </ContentLoader>
    </div>
  );
};
export const AccordionSkeleton = () => {
  const height = 70;
  return (
    <div className={classNames(styles.skeleton, styles.accordion)}>
      <ContentLoader
        height={height}
        speed={1}
        // backgroundColor={'#333'}
        // foregroundColor={'#999'}
        // viewBox="0 0 380 70"
      >
        <circle cx={height / 2} cy={height / 2} r='20' />
        <rect x='68' y='25' rx='2' ry='2' width='200' height='20' />
        <rect x='300' y='25' rx='2' ry='2' width='168' height='20' />
        <rect x='500' y='25' rx='2' ry='2' width='240' height='20' />
        {/* <rect x="68" y="38" rx="2" ry="2" width="240" height="10" /> */}
      </ContentLoader>
    </div>
  );
};

export const ChartSkeleton = () => {
  const height = 280;
  return (
    <div className={classNames(styles.skeleton, styles.chart)}>
      <ContentLoader height={height} speed={1}>
        <circle cx={height / 2} cy={height / 2} r={height / 2.5} />
        <rect x={height} y={height / 10} rx='2' ry='2' width={height} height={height * 0.8} />
      </ContentLoader>
    </div>
  );
};

export function ContentBox(props: PropsWithChildren<BoxProps>) {
  return (
    <Box {...props} className={classNames(props.className, styles.contentBox)}>
      {props.children}
    </Box>
  );
}
// export interface SkeletonProps {
//   // kind?: 'rect',
//   rows?: string[];
//   className?: string;
// }
// export function Skeleton(props: SkeletonProps) {
//   const classes: Record<string, boolean> = {};
//   const rows = props.rows || ['0##', '###', '###'];
//   // classes[styles[props.kind || 'rect']] = true;

//   return (
//     <Box flexGrow='1' kind='vflex' gap='s' className={classNames(styles.skeleton, props.className)}>
//       {rows.map((r, i1) => {
//         const cols = r.split('');
//         return <Box kind='hflex' flexGrow='1' gap='s' className={styles.row} key={i1}>
//           {cols.map((c, i) => <Box flexGrow='1' className={classNames(styles.shimmer, styles.col)} key={i}> </Box>)}
//         </Box>
//       })}
//     </Box>
//   )
// }
