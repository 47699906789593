import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import ButtonV2 from '../../components-v2/button';
import InputV2, { FormContainerV2 } from '../../components-v2/input';
import Button from '../../components/button';
import EditPoup from '../../components/edit-popup';
import { FormCard, FormCardBody, FormCardCol, FormCardPropLbl, FormCardPropValue, FormCardRow } from '../../components/form-card';
import Input from '../../components/input';
import { NodeHierarchyLabelInverted } from '../../components/org-hierarchy/node-hierarchy-label';
import { mapItems } from '../../components/org-hierarchy/org-diagramm-utils';
import OrgHierarchyDiagramm, { OrgHierarchyDiagrammSettings, useOrgHiearchyDiagramm } from '../../components/org-hierarchy/org-hierarchy-diagramm';
import Page from '../../components/page';
import { FormRow, Spacer } from '../../components/page-layout';
import { useApi } from '../../hooks/useApi';
import useFormState from '../../hooks/useFormState';
import api from '../../services/api';
import { ApiError, ClientHierarchyNodeTypeDto, SharedClientDto, SharedClientHierarchyNodeDto } from '../../services/api-client/csp-api';
import { useUserPreferences } from '../../utils/UsePreferenceProvider';
import styles from './dev-tools-page.module.scss';

export interface DevToolsPagePageProps {
  className?: string;
}

export default function DevToolsPage(props: DevToolsPagePageProps) {
  const localPrefs = useUserPreferences();

  const [provisionGigyaMsg, setProvisionGigyaMsg] = useState('no message');
  const [provisionGigyaMails, setProvisionGigyaMails] = useState('');

  const [gigyaMailToRetrieve, setGigyaMailToRetrieve] = useState('');
  const [gigyaProfileRetrieved, setGigyaProfileRetrieved] = useState('');

  const onProvisionGigyaClicked = async () => {
    const mails = provisionGigyaMails
      .split('\n')
      .map((m) => m.trim().toLocaleLowerCase())
      .filter((m) => !!m);
    const resp = await api.devTools.provisionGigyaUsers({ users: mails });
    const msg = resp.data.message;
    setProvisionGigyaMsg(msg);
  };

  //****************** invalid permissions ******************//
  const [cleanRolesErr, setCleanRolesErr] = useState('');
  const [invalidRoles, , , refreshInvalidRoles] = useApi({
    call: () => api.devTools.getInvalidPermissionAssociations(),
    map: (data) => data?.invalidRoles,
  });

  const onCleanupInvalidPermissionsClick = async () => {
    setCleanRolesErr('');
    try {
      await api.devTools.removeInvalidPermissionAssociations();
    } catch (e) {
      const err = e as any;
      if (err.error) {
        setCleanRolesErr(JSON.stringify(err.error));
      } else {
        setCleanRolesErr(JSON.stringify(err));
      }
    }
    refreshInvalidRoles();
  };

  //****************** queue stats ******************//
  const [totalQueueSize, fetchingTotalQueueSize, , refreshTotalQueueSize] = useApi({
    call: () => api.devTools.getTotalQueueLength(),
    map: (r) => r?.value,
  });

  //****************** youtrack ******************//
  const youtrackTicketForm = useFormState({
    submitHandler: async () => {
      // youtrackTitle
      const response = await api.devTools.createYoutrackIssue({
        summary: `TEST TICKET FROM ECS ${youtrackTitle.value}`,
        description: youtrackDescription.value,
      });

      alert(`ticket created: ${response.data.ticketId}`);
    },
  });
  const [youtrackTitle] = youtrackTicketForm.useInputState({ label: 'title', initialValue: '', maxLength: 100 });
  const [youtrackDescription] = youtrackTicketForm.useInputState({ label: 'desc', initialValue: '', maxLength: 100 });

  //****************** youtrack ******************//

  //****************** station statistics ******************//
  const [stationStats, setStationStats] = useState('');
  const [stationStatsChargeBoxId, setStationStatsChargeBoxId] = useState('');

  //****************** assign stations ******************//
  const [evseIdToAssign, setEvseIdToAssign] = useState('');
  const [nodeSelectorClient, setNodeSelectorClient] = useState<SharedClientDto | undefined>();
  const [assignStationNode, setAssignStationNode] = useState<SharedClientHierarchyNodeDto>();

  const [nodeAssignmentData, , ,] = useApi({
    call: () => api.devTools.getClientHierarchues(),
    map: (data) => {
      if (!data?.clients || data.clients.length <= 0) return data;

      const localClient = localPrefs.clientCode ? data.clients.find((c) => c.code === localPrefs.clientCode) : null;
      setNodeSelectorClient(localClient || data?.clients[0]);
      return data;
    },
  });

  const diagProps = useMemo(() => {
    const settings: OrgHierarchyDiagrammSettings = {
      diagramProps: {
        readonly: true,
        className: styles.diagram,
        nodesSelectable: true,
      },
      clientRootChangable: true,
      searchClientRoot: async (ctx, search): Promise<SharedClientDto[]> => {
        if (!nodeAssignmentData) return [];
        const result = nodeAssignmentData.clients.filter(
          (c) => c.code.toLowerCase().indexOf(search.toLowerCase()) >= 0 || c.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
        );

        return result;
      },
      onClientPicked: (ctx, code, client) => {
        setAssignStationNode(undefined);
        setNodeSelectorClient(client);
      },
      onItemClick: (ctx, node, allItems) => {
        if (node.type !== ClientHierarchyNodeTypeDto.Area) return;

        if (!nodeSelectorClient) return;
        const selectedNode = nodeSelectorClient.hierarchy.find((n) => n.code === node.code);
        if (!selectedNode) return;
        setAssignStationNode(selectedNode);
        setShowSelectNodePopup(false);
      },
    };

    return settings;
  }, [nodeAssignmentData, nodeSelectorClient]);

  const [showSelectNodePopup, setShowSelectNodePopup] = useState(false);
  const [selectNodeDiagramProps, selectNodeDiagramContext] = useOrgHiearchyDiagramm(diagProps);

  useEffect(() => {
    if (!showSelectNodePopup) return;
    if (!nodeAssignmentData) return;
    if (!nodeSelectorClient) return;
    const mapped = mapItems(nodeSelectorClient.hierarchy, nodeSelectorClient.code, true);
    selectNodeDiagramContext.setItems(mapped);
    selectNodeDiagramContext.fitToView();
  }, [showSelectNodePopup, nodeAssignmentData, nodeSelectorClient, selectNodeDiagramContext]);

  const onWantAssignEvseIdToNode = async () => {
    if (!evseIdToAssign) return;
    if (!assignStationNode) return;
    if (!nodeSelectorClient) return;

    try {
      await api.devTools.assignStationToClient({ clientHierarchy: assignStationNode.path!, code: nodeSelectorClient.code, evseId: evseIdToAssign });
      alert('DONE!');
    } catch (e) {
      alert(`${e}`);
    }
  };

  //****************** reflect evp resources ******************//
  const [evpImportRunning, setEvpImportRunning] = useState(false);
  const reflectEvpResources = async () => {
    try {
      setEvpImportRunning(true);
      await api.devTools.reflectEvpResources();
      const resultText = 'Success';
      alert(resultText);
      setEvpImportRunning(false);
    } catch (e: any) {
      setEvpImportRunning(false);
      if (e.error) {
        alert(`${e.error?.statusCode}: ${e.error?.message}`);
      } else {
        alert('Unknown error');
      }
    }
  };
  //****************** reflect evp resources ******************//

  return (
    <>
      <Page breadCrumb={[{ title: 'Tools', href: '/dev/tools', active: true }]} className={styles.root}>
        <FormCard tabletSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            {invalidRoles && (
              <>
                <h4>Unknown Permissions</h4>
                <aside>
                  <b>{invalidRoles.length}</b> roles with not existing permissions:
                </aside>
                <ul>
                  {invalidRoles.map((r) => {
                    return (
                      <li key={r.roleId}>
                        <b>{r.roleName}</b>
                        <ul>
                          {r.permissions.map((p, i) => {
                            return <li key={i}>{p}</li>;
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
                <Button onClick={onCleanupInvalidPermissionsClick} kind='danger'>
                  Cleanup
                </Button>
                {cleanRolesErr && <p>API Error: {cleanRolesErr}</p>}
              </>
            )}
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            {nodeAssignmentData && (
              <>
                <h4>Assign Station to Client</h4>
                {!assignStationNode && (
                  <Button
                    onClick={() => {
                      setShowSelectNodePopup(true);
                    }}
                  >
                    Choose Client Node
                  </Button>
                )}
                {nodeSelectorClient && assignStationNode && (
                  <>
                    <NodeHierarchyLabelInverted allNodes={nodeSelectorClient.hierarchy} code={assignStationNode.code} />
                    <Button
                      kind='warn'
                      onClick={() => {
                        setAssignStationNode(undefined);
                      }}
                    >
                      Unselect
                    </Button>
                    <Input className={styles.evseid} onChange={(e) => setEvseIdToAssign(e)} type={'text'} label={'evseid'} value={evseIdToAssign} />
                    <Button kind='primary' onClick={() => onWantAssignEvseIdToNode()}>
                      Assign
                    </Button>
                  </>
                )}
              </>
            )}
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            <h4>Provision GigyaUsers</h4>
            <textarea value={provisionGigyaMails} onChange={(e) => setProvisionGigyaMails(e.target.value)}></textarea>
            <pre>{provisionGigyaMsg}</pre>
            <Button onClick={() => onProvisionGigyaClicked()}>Create Users in Gigya</Button>
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            <h4>Retrieve Gygiya user</h4>
            <Input className={styles.evseid} onChange={(e) => setGigyaMailToRetrieve(e)} type={'text'} label={'mail'} value={gigyaMailToRetrieve} />

            <textarea disabled value={gigyaProfileRetrieved} onChange={() => {}}></textarea>
            {/* <pre >{gigyaProfileRetrieved}</pre> */}
            <Button
              onClick={async () => {
                try {
                  if (!gigyaMailToRetrieve) return;
                  setGigyaProfileRetrieved('fetching');
                  const resp = await api.devTools.getGigyaUserProfile({ mail: gigyaMailToRetrieve });
                  const str = await resp.data.content;
                  setGigyaProfileRetrieved(str);
                } catch (e) {
                  const err = e as ApiError;
                  setGigyaProfileRetrieved(`failed with error: ${err.statusCode} (${err.message} / ${err.error})`);
                }
              }}
            >
              Get User
            </Button>
          </FormCardBody>
        </FormCard>

        <FormCard desktopSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            <h4>Seeding and Import</h4>
            <FormCardRow>
              <FormCardCol>
                <FormCardPropLbl>Total Message Count</FormCardPropLbl>
                <FormCardPropValue>{fetchingTotalQueueSize ? 'loading' : totalQueueSize || 0}</FormCardPropValue>
                <Button className={classNames(styles.btnS, styles.refreshMsgCntBtn)} kind='accent' onClick={() => refreshTotalQueueSize()}>
                  Refresh
                </Button>
              </FormCardCol>
            </FormCardRow>

            <FormCardRow>
              <Button
                onClick={() => {
                  api.devTools
                    .seedRma()
                    .then(() => alert('triggered seeding'))
                    .catch((e) => alert('seeding failed'));
                }}
                className={styles.btnS}
                kind='danger'
              >
                Seed RMA
              </Button>
              <Button
                onClick={() => {
                  api.devTools
                    .seedHtbDataService()
                    .then(() => alert('triggered seeding'))
                    .catch((e) => alert('seeding failed'));
                }}
                className={styles.btnS}
                kind='danger'
              >
                Seed HTP Data Service
              </Button>
            </FormCardRow>
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            <h4>Create Youtrack Ticket</h4>
            <Input {...youtrackTitle} />
            <label>{youtrackDescription.label}</label>
            <textarea {...youtrackDescription} onChange={(e) => youtrackDescription.onChange(e.currentTarget.value)} />

            <Button onClick={() => youtrackTicketForm.submit()}>Create Ticket</Button>
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='half'>
          <FormCardBody className={classNames(styles.tile)}>
            <h4>EVP</h4>
            <Button onClick={reflectEvpResources} disabled={evpImportRunning}>
              Reflect EVP Resources
            </Button>
          </FormCardBody>
        </FormCard>

        <FormCard tabletSize='full'>
          <FormCardBody className={classNames(styles.tile)}>
            <h4>Get Chargebox statistics</h4>
            <FormRow>
              <FormContainerV2 w='1' label='ChargeBoxId'>
                <InputV2 type='text' value={stationStatsChargeBoxId} onChange={(e) => setStationStatsChargeBoxId(e)} />
              </FormContainerV2>
              <ButtonV2
                onClick={async () => {
                  try {
                    setStationStats('loading ...');
                    const stats = await api.devTools.getStationConnectorStats(stationStatsChargeBoxId);
                    const obj = stats.data;
                    const json = JSON.stringify(obj, null, 2);
                    setStationStats(json);
                  } catch (e) {
                    setStationStats(`error: ${e}`);
                  }
                }}
                size='s'
              >
                Fetch Statistics
              </ButtonV2>
              <Spacer w='2' />
            </FormRow>
            <FormRow>
              <FormContainerV2 w='1'>
                <textarea rows={20} value={stationStats} style={{ width: '100%', fontSize: '10px' }}></textarea>
              </FormContainerV2>
            </FormRow>
            {/* <h4>EVP</h4>
            <Button onClick={reflectEvpResources} disabled={evpImportRunning}>
              Reflect EVP Resources
            </Button> */}
          </FormCardBody>
        </FormCard>
      </Page>

      <EditPoup
        open={showSelectNodePopup}
        onClose={() => setShowSelectNodePopup(false)}
        bodyClassName={styles.popupBody}
        footerClassName={styles.footer}
        actionsClassName={styles.actions}
        title={'Bereich auswählen'}
        // showCancel={props.edit}
        saveText={'Auswählen'}
      >
        <OrgHierarchyDiagramm {...selectNodeDiagramProps} />
      </EditPoup>
    </>
  );
}
