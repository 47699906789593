import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ReactComponent as BackButton } from '../../assets/back.svg';
import { ReactComponent as StationIco } from '../../assets/charging-station-blue.svg';
import { ReactComponent as PlugIco } from '../../assets/plug-blue.svg';
import Accordion from '../../components/accordion/accordion';
import { TIME_FILTER_KEYS, TimeFilterKey } from '../../components/chart-filter';
import ClusterDiagramsPanel from '../../components/cluster/cluster-diagrams-panel';
import ClusterWorkLoadBox from '../../components/cluster/cluster-workload-box';
import { FormCard, FormCardBody } from '../../components/form-card';
import IconExplanationSection from '../../components/icon-explanation-section';
import { StationFilterParameters } from '../../components/infrastructure/filter-area-parameters.dto';
import StationAppliedTags from '../../components/infrastructure/station-applied-tags';
import StationFilterArea from '../../components/infrastructure/station-filter-area';
import StationListItem from '../../components/infrastructure/station-list-item';
import Page, { ListingCardPlaceholder } from '../../components/page';
import Paging from '../../components/paging';
import StationStatusSummaryPanel from '../../components/station-status-summary-panel/station-status-summary-panel';
import TabPanel, { Tab, Tabs } from '../../components/tab-panel';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { createQueryString, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { WorkloadLevel } from '../../services/api-client/csp-api';
import { dateRangeValidator, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import ChargePointRemoteActions, { ChargePoint, useChargePointRemoteActions } from '../stations/chargepoint-remote-actions';
import StationRemoteActions, { useStationRemoteActions } from '../stations/station-remote-actions';
import styles from './clusters-detail-page.module.scss';
import { getTimeRange } from './clusters-util';

export default function ClusterStationsDetailPage(props: {}) {
  const { _t } = useBetterTranslate('clusters-detail-stations-page');
  const navigate = useBetterNavigate();

  const MIN_DATE = useMemo(() => moment().subtract(6, 'month').startOf('day'), []);
  const MAX_DATE = useMemo(() => moment().endOf('day'), []);

  type FilterParameters = {
    from?: string;
    to?: string;
    skip?: number | null;
    limit?: number | null;
    clientCode?: string;
    nodes?: string[];
    selectedTimeFilter?: TimeFilterKey;
  } & StationFilterParameters;
  const setFilterParams = (filter: FilterParameters) => {
    const { ...params } = filter;
    _setInnerFilterParams({ ...params });
  };
  const { clusterId = '' } = useParams();
  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), selectedTimeFilter: validateString(TIME_FILTER_KEYS) };

  const defaultFrom = moment().subtract(1, 'day').startOf('minute').toISOString();
  const defaultTo = moment().startOf('minute').toISOString();
  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: defaultFrom,
      to: defaultTo,
      selectedTimeFilter: '24h',
    },
    validators
  );

  const [searchTerm, setSearchTerm] = useState(filterParams.search);
  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [clusterResp, , clusterApiErr] = useApi(
    {
      call: async (clientCode?: string, clusterId?: string, from?: string, to?: string, selectedTimeFilter?: TimeFilterKey) => {
        const timeRange = getTimeRange(selectedTimeFilter || '24h', from, to);
        const res = await api.clusterDetail.getClusterDetails({
          clientCode: clientCode || '',
          clusterId: clusterId || '',
          from: timeRange.from,
          to: timeRange.to,
        });
        return res;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    clusterId,
    filterParams.from,
    filterParams.to,
    filterParams.selectedTimeFilter
  );

  const [summaryResp, , summaryApiErr] = useApi(
    {
      call: async (clientCode?: string, clusterId?: string) => {
        if (!clientCode) return undefined;
        return await api.clusterDetail.getSummary({
          client: clientCode,
          hierarchyNodeCodes: [clientCode],
          clusterId: clusterId,
        });
      },
      map: (data) => {
        if (!data) return data;
        const stationsTotal = data.summary.stationsOnline + data.summary.stationsOffline + data.summary.stationsFailure + data.summary.stationsNotInOperation;
        const connectorsTotal =
          data.summary.connectorsAvailable +
          data.summary.connectorsCharging +
          data.summary.connectorsUnavailable +
          data.summary.connectorsUnknown +
          data.summary.connectorsFailure +
          data.summary.connectorsOccupied;
        const totalEnergyConsumption = data.summary.stationsAssumedEnergy;
        return {
          clientContext: data.clientContext,
          totalEnergyConsumption: totalEnergyConsumption,
          stationsSeries: {
            online: data.summary.stationsOnline,
            offline: data.summary.stationsOffline,
            failures: data.summary.stationsFailure,
            notInOperation: data.summary.stationsNotInOperation,
            total: stationsTotal,
          },
          connectorsSeries: {
            available: data.summary.connectorsAvailable,
            charging: data.summary.connectorsCharging,
            unavailable: data.summary.connectorsUnavailable,
            unknown: data.summary.connectorsUnknown,
            occupied: data.summary.connectorsOccupied,
            failure: data.summary.connectorsFailure,
            total: connectorsTotal,
          },
        };
      },
    },
    filterParams.clientCode,
    clusterId
  );

  const [stationsResp, stationsFetching, stationsApiErr, stationsRefresh] = useApi(
    {
      call: async (
        clientCode?: string,
        skip?: number | null,
        limit?: number | null,
        query?: string,
        online?: string,
        offline?: string,
        failure?: string,
        notInOperation?: string,
        ac?: string,
        dc?: string,
        acdc?: string,
        clusterId?: string,
        isPublic?: string
      ) => {
        if (!clientCode) return undefined;
        const res = await api.clusterDetail.listClusterStations({
          limit: Number(limit || 20),
          skip: Number(skip || 0),
          client: clientCode,
          search: query || undefined,
          online: online === '1',
          offline: offline === '1',
          failure: failure === '1',
          notInOperation: notInOperation === '1',
          ac: ac === '1',
          dc: dc === '1',
          acdc: acdc === '1',
          clusterId: clusterId,
          isPublic: isPublic ? isPublic === '1' : undefined,
        });
        setHasExternalHardware(!!res.data.stations.find((s) => s.isExternalHardware));
        setHasPublicStations(!!res.data.stations.find((s) => s.isPublic));
        return res;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.online,
    filterParams.offline,
    filterParams.failure,
    filterParams.notInOperation,
    filterParams.ac,
    filterParams.dc,
    filterParams.acdc,
    clusterId,
    filterParams.public
  );

  useDebounce(
    () => {
      if (searchTerm === filterParams.search) return;
      setFilterParams({ search: searchTerm });
    },
    800,
    [searchTerm, filterParams.search]
  );

  useEffect(() => {
    setSearchTerm(filterParams.search);
  }, [filterParams.search]);

  const navigateToClusterListPage = (filter: {}) => {
    const link = `/loadmanagement/${filterParams.clientCode}`;
    navigate(link);
  };

  const navigateToClusterDetailChargepointPage = (filter: {
    available?: number;
    charging?: number;
    occupied?: number;
    failure?: number;
    unavailable?: number;
    unknown?: number;
    nodes?: string[];
  }) => {
    const link = `/loadmanagement/${filterParams.clientCode}/cluster-chargepoints/${clusterId}`;
    navigate(link, filter);
  };

  const fetchStationDetails = async (chargeBoxId: string) => {
    const res = await api.clusterDetail.getChargepointsOfStation({
      hierarchyNodeCodes: [filterParams.clientCode!],
      client: filterParams.clientCode!,
      chargeBoxId: chargeBoxId,
    });
    return res.data;
  };

  const [expandEnergyPanel, setExpandEnergyPanel] = useState<boolean>(true);
  const [expandEnergyGraph, setExpandEnergyGraph] = useState<boolean>(true);

  const remoteActionsStation = useStationRemoteActions({
    refreshRequested: async () => {
      stationsRefresh();
    },
    changeAvailability: {
      do: async (station, availaility) => (await api.clusterDetail.remoteChangeStationAvailability({ chargeBoxId: station.chargeBoxId, type: availaility })).data,
    },
    reset: {
      do: async (station) => (await api.clusterDetail.resetStation(station.chargeBoxId)).data,
    },
  });

  const remoteActionsChargePoint = useChargePointRemoteActions({
    refreshRequested: async () => {
      stationsRefresh();
    },
    remoteStop: {
      do: async (cp) => (await api.clusterDetail.remoteStopSession({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteStart: {
      do: async (cp, card) =>
        (await api.clusterDetail.remoteStartSession({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.clusterDetail.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteReserve: {
      do: async (cp, card, minutes) =>
        (await api.clusterDetail.remoteReserve({ cardExtId: card.extId, cardNumber: card.id, chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, minutes: minutes })).data,
      searchCards: async (q) => (await api.clusterDetail.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteCancelReserve: {
      do: async (cp) => (await api.clusterDetail.remoteReserveCancel({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
      searchCards: async (q) => (await api.clusterDetail.getCards(filterParams.clientCode!, { search: q })).data.cards,
    },
    remoteUnlock: {
      do: async (cp) => (await api.clusterDetail.remoteUnlock({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId })).data,
    },
    remoteChangeAvailability: {
      do: async (cp, av) => (await api.clusterDetail.remoteChangeChargePointAvailability({ chargeBoxId: cp.chargeBoxId, connectorId: cp.connectorId, type: av })).data,
    },
  });

  return (
    <Page
      breadCrumb={[{ title: 'LASTMANAGEMENT', href: '/loadmanagement', active: true }]}
      fetching={stationsFetching}
      placeHolder={<ListingCardPlaceholder />}
      className={styles.root}
      error={clusterApiErr || summaryApiErr || stationsApiErr}
      outOfPlaceHolder={
        <>
          {/* Context popups */}
          <StationRemoteActions {...remoteActionsStation.stationRemoteActionsProps} />
          <ChargePointRemoteActions {...remoteActionsChargePoint.chargePointRemoteActionsProps} />
          {clusterResp && clusterResp.clusterCount > 1 && (
            <div className={classNames(styles.clusterOverviewLink, styles.textLink)} onClick={navigateToClusterListPage}>
              <BackButton /> <span>{_t('Zur Cluster Übersicht')}</span>
            </div>
          )}
          {summaryResp && (
            <>
              <FormCard tabletSize='full'>
                <Accordion
                  expanded={expandEnergyPanel}
                  onExpand={() => setExpandEnergyPanel(!expandEnergyPanel)}
                  headline={
                    clusterResp &&
                    clusterResp.cluster && (
                      <div className={styles.headline}>
                        <div className={styles.label}>{`${_t('Lastmanagement')} ${clusterResp.cluster.displayName}`}</div>
                        <ClusterWorkLoadBox workloadLevel={clusterResp.cluster.workload as WorkloadLevel} className={styles.workload} />
                      </div>
                    )
                  }
                >
                  <StationStatusSummaryPanel
                    connectors={{
                      overallTotal: summaryResp.connectorsSeries.total,
                      total: summaryResp.connectorsSeries.total,
                      available: summaryResp.connectorsSeries.available,
                      charging: summaryResp.connectorsSeries.charging,
                      failure: summaryResp.connectorsSeries.failure,
                      occupied: summaryResp.connectorsSeries.occupied,
                      unavailable: summaryResp.connectorsSeries.unavailable,
                      unknown: summaryResp.connectorsSeries.unknown,
                      onAvailableClicked: () => navigateToClusterDetailChargepointPage({ available: 1 }),
                      onChargingClicked: () => navigateToClusterDetailChargepointPage({ charging: 1 }),
                      onOccupiedClicked: () => navigateToClusterDetailChargepointPage({ occupied: 1 }),
                      onUnavailableClicked: () => navigateToClusterDetailChargepointPage({ unavailable: 1 }),
                      onUnknownClicked: () => navigateToClusterDetailChargepointPage({ unknown: 1 }),
                      onFailureClicked: () => navigateToClusterDetailChargepointPage({ failure: 1 }),
                    }}
                    stations={{
                      overallTotal: summaryResp.stationsSeries.total,
                      total: summaryResp.stationsSeries.total,
                      failures: summaryResp.stationsSeries.failures,
                      offline: summaryResp.stationsSeries.offline,
                      online: summaryResp.stationsSeries.online,
                      notInOperation: summaryResp.stationsSeries.notInOperation,
                      onOnlineClicked: () => setFilterParams({ online: '1', offline: undefined, failure: undefined, notInOperation: undefined }),
                      onOfflineClicked: () => setFilterParams({ online: undefined, offline: '1', failure: undefined, notInOperation: undefined }),
                      onFailureClicked: () => setFilterParams({ online: undefined, offline: undefined, failure: '1', notInOperation: undefined }),
                      onNotInOperationClicked: () => setFilterParams({ online: undefined, offline: undefined, failure: undefined, notInOperation: '1' }),
                    }}
                  />
                </Accordion>
              </FormCard>
            </>
          )}
          {clusterResp && clusterResp.cluster && (
            <FormCard tabletSize='full'>
              <ClusterDiagramsPanel
                expanded={expandEnergyGraph}
                cluster={clusterResp.cluster}
                historicalData={clusterResp.historicalData}
                onExpand={() => setExpandEnergyGraph(!expandEnergyGraph)}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                onChangeDate={_setInnerFilterParams}
                selectedTimeFilter={filterParams.selectedTimeFilter}
              />
            </FormCard>
          )}

          {(hasExternalHardware || hasPublicStations) && (
            <FormCard phoneSize='full'>
              <FormCardBody>
                <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
              </FormCardBody>
            </FormCard>
          )}

          <FormCard phoneSize='full'>
            <TabPanel>
              <Tabs>
                <Tab active={true} txt={_t('Ladestationen')} ico={<StationIco />}></Tab>
                <Tab txt={_t('Ladepunkte')} onClick={() => navigateToClusterDetailChargepointPage({})} ico={<PlugIco />}></Tab>
              </Tabs>
            </TabPanel>
          </FormCard>
          <FormCard phoneSize='full' className={classNames(styles.tabContent)}>
            <StationFilterArea filterParams={filterParams} searchTerm={searchTerm} setFilterParams={setFilterParams} updateSearchTerm={updateSearchTerm}></StationFilterArea>
          </FormCard>
          <FormCard phoneSize='full' className={classNames(styles.selectedTags, styles.tabContent)}>
            <StationAppliedTags setFilterParams={setFilterParams} show={{ ac: !!filterParams.ac, dc: !!filterParams.dc, acdc: !!filterParams.acdc }}></StationAppliedTags>
          </FormCard>
        </>
      }
    >
      <div className={classNames(styles.stationListContainer, styles.tabContent)}>
        {stationsResp &&
          stationsResp.stations.map((station, i) => {
            const sessionsQuery = createQueryString({ entity: [{ id: station.chargeBoxId, type: 'station' }] });
            const sessionsLink = `/${stationsResp?.sessionsPagePrefix}-charging-sessions/${filterParams.clientCode}?${sessionsQuery}`;
            const chargepointsPath = `/loadmanagement/${filterParams.clientCode}/cluster-chargepoints/${station.clusterId}`;
            const hasStationContextItems = remoteActionsStation.hasStationRemoteActions({
              can: station.can,
              chargeBoxId: station.chargeBoxId,
              domainStatus: station.domainStatus! as any,
              // hasChargingConnectors: hasAnyChargingConn(station),
            });
            const hasChargePointContextItems = (cp: ChargePoint) => remoteActionsChargePoint.hasChargePointRemoteActions(cp);
            return (
              <StationListItem
                item={station}
                allNodes={[...(stationsResp?.clientContext.accessableNodes || []), ...(stationsResp?.clientContext.forbiddenParents || [])]}
                sessionsLink={sessionsLink}
                chargepointsPath={chargepointsPath}
                clientCode={filterParams.clientCode}
                loadManagementLinkActive={true}
                key={station.chargeBoxId}
                hasStationContextItems={hasStationContextItems}
                hasChargePointContextItems={hasChargePointContextItems}
                onClickStationRemoteActionButton={(ev: React.MouseEvent<Element, MouseEvent>) =>
                  remoteActionsStation.showStationRemoteActionContextMenu(ev, {
                    can: station.can,
                    chargeBoxId: station.chargeBoxId,
                    domainStatus: station.domainStatus! as any,
                    // hasChargingConnectors: hasAnyChargingConn(station),
                  })
                }
                onClickChargePointRemoteActionButton={remoteActionsChargePoint.showChargePointRemoteActionContextMenu}
                fetchStationDetails={() => fetchStationDetails(station.chargeBoxId)}
                onClickChangeCustomNameStation={() => {}}
                onClickChangeCustomNameChargePoint={(cp) => {}}
                hasChangeCustomNamePopup={false}
              ></StationListItem>
            );
          })}
      </div>

      {stationsResp && (
        <Paging
          className={classNames(styles.pagination, styles.tabContent)}
          skip={stationsResp.skip}
          limit={stationsResp.limit}
          total={stationsResp.total}
          onChange={(arg) => {
            setFilterParams({
              skip: arg.skip <= 0 ? null : arg.skip,
              limit: arg.limit,
            });
          }}
        />
      )}
    </Page>
  );
}
