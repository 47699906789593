import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { ReactComponent as ErrorIco } from '../../assets/error.svg';
import { ReactComponent as NotFoundIco } from '../../assets/not-found.svg';
import { ReactComponent as SpinnerIco } from '../../assets/spinner2.svg';
import { ReactComponent as SuccessIco } from '../../assets/success.svg';
import { ReactComponent as UserLockIco } from '../../assets/user-lock.svg';
import { ApiError } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import Box from '../utils';
import styles from './page.module.scss';

export interface PageProps {
  className?: string;
}
export function Page(props: PropsWithChildren<PageProps>) {
  return <section className={classNames(styles.page, props.className)}>{props.children}</section>;
}

export function PageContent(props: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={classNames(styles.content, props.className)}>
      <Box className={styles.contentInner}>{props.children}</Box>
    </div>
  );
}

export interface PageHeaderProps {
  className?: string;
}
export function PageHeader(props: PropsWithChildren<PageHeaderProps>) {
  return (
    <Box className={classNames(styles.header, props.className)} kind={'hflex'} gap='s'>
      {props.children}
    </Box>
  );
}

export function PageTitle(props: PropsWithChildren<{ className?: string }>) {
  return (
    <Box fs={'xl'} fw='700'>
      {props.children}
    </Box>
  );
}

export interface ApiBasedContentProps<T> {
  err?: ApiError;
  fetching?: boolean;
  placeholder?: () => JSX.Element;
  resp?: T;
  children?(resp: T): JSX.Element;
  customErrorMsg?: (err: ApiError) => string;
}
export function ApiBasedContent<T>(props: ApiBasedContentProps<T>) {
  if (props.err)
    return (
      <ApiErrorPanel customErromsg={props.customErrorMsg} apiError={props.err}>
        ERROR
      </ApiErrorPanel>
    );
  if (props.fetching) {
    if (props.placeholder) return props.placeholder();
    else return <LoadingSpinner />;
  }
  if (props.resp) return props.children?.(props.resp);
  console.error(`api based content without error not fethcing and no response`);
}

export interface LoadingSpinnerProps {
  className?: string;
  panelContentClassName?: string;
}
export function LoadingSpinner(props: PropsWithChildren<LoadingSpinnerProps>) {
  const { _t } = useBetterTranslate('page-layout');
  return (
    <div className={classNames(styles.spinner, props.className)}>
      <SpinnerIco />
      <div className={classNames(styles.panelContent, props.panelContentClassName)}>{props.children || <span className={styles.defaultLoadingMsg}>{_t(`loading`)}</span>}</div>
    </div>
  );
}

export function SuccessPanel(props: PropsWithChildren<{ className?: string; panelContentClassName?: string }>) {
  return (
    <div className={classNames(styles.successPanel, props.className)}>
      <div className={styles.ico}>
        <SuccessIco />
      </div>
      <div className={classNames(styles.panelContent, props.panelContentClassName)}>{props.children}</div>
    </div>
  );
}

export function ErrorPanel(props: PropsWithChildren<{ className?: string; panelContentClassName?: string; ico?: ReactNode }>) {
  return (
    <div className={classNames(styles.errorPanel, props.className)}>
      <div className={styles.ico}>{props.ico || <ErrorIco />}</div>
      <div className={classNames(styles.panelContent, props.panelContentClassName)}>{props.children}</div>
    </div>
  );
}

export function ApiErrorPanel(
  props: PropsWithChildren<{ className?: string; panelContentClassName?: string; apiError: ApiError; customErromsg?: (err: ApiError) => string | undefined }>
) {
  const { _t } = useBetterTranslate('page');
  if (props.apiError.statusCode === 403) {
    return (
      <ErrorPanel className={styles.apiError} ico={<UserLockIco />}>
        <div className={styles.code}>{`CODE 403`}</div>
        <div className={styles.txt}>{props.customErromsg?.(props.apiError) || _t('You dont have permissions to access this page')}</div>
      </ErrorPanel>
    );
  } else if (props.apiError.statusCode === 404) {
    return (
      <ErrorPanel className={styles.apiError} ico={<NotFoundIco />}>
        <div className={styles.code}>{`CODE 404`}</div>
        <div className={styles.txt}>{props.customErromsg?.(props.apiError) || _t('This page could not be found')}</div>
      </ErrorPanel>
    );
  } else {
    return (
      <ErrorPanel className={styles.apiError} ico={<NotFoundIco />}>
        <div className={styles.code}>CODE {props.apiError?.statusCode || 'UNBEKANNT'}</div>
        <div className={styles.txt}>{props.customErromsg?.(props.apiError) || _t('An unexpected error occoured')}</div>
      </ErrorPanel>
    );
  }
}
