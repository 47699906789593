import classNames from 'classnames';
import MainLogoLg from '../assets/main-logo-lg.png';
import Button from '../components/button';
import useBetterTranslate from '../utils/translation-utils';
import styles from './logout-page.module.scss';

export default function LogoutPage(props: {}) {
  const { _t } = useBetterTranslate('logout-page');

  return (
    <div className={classNames(styles.root)}>
      {/* <div> */}
      <img alt='Charging Solution Platform' src={MainLogoLg} className={classNames(styles.logo)} />
      <h2 className={classNames(styles.title)}>Charging Solution Platform</h2>

      <div className={styles.loginBox}>
        <span>{_t('Sie sind abgemeldet')}</span>
        <Button onClick={() => window.location.replace('/')} kind='primary'>
          {_t(`Anmelden`)}
        </Button>
      </div>

      {/* </div> */}
    </div>
  );
}
