import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-use';
import { ReactComponent as AdminIco } from '../../assets/admin.svg';
import { ReactComponent as ArrowClosePanelIco } from '../../assets/arrow-close-panel.svg';
import { ReactComponent as ChargerIco } from '../../assets/charger.svg';
import { ReactComponent as ChargingCardIco } from '../../assets/charging-cards.svg';
import { ReactComponent as ChargingRecordIco } from '../../assets/charging-records.svg';
import { ReactComponent as OriganisationIco } from '../../assets/hierarchy.svg';
import { ReactComponent as LandPlotIco } from '../../assets/land-plot.svg';
import { ReactComponent as LoadManagementIco } from '../../assets/load-management.svg';
import { ReactComponent as StatusLogIco } from '../../assets/status-log.svg';
// import { ReactComponent as AdminIco } from '../../assets/admin.svg';
import classNames from 'classnames';
import api from '../../services/api';
import { useAuth } from '../../utils/AuthProvider';
import { useUserPreferences } from '../../utils/UsePreferenceProvider';
import ifTrue from '../../utils/class-name';
import useBetterTranslate from '../../utils/translation-utils';
import useEffectAsync from '../../utils/useEffectAsync';
import Ico from '../ico';
import Box from '../utils';
import styles from './main-nav.module.scss';

export interface MenuItem {
  title: string;
  href: string;
  nested: MenuItem[];
  style?: 'extended' | 'simple';
  dataCy?: string;
  icon?: React.ReactNode;
}

export function MainNav(props: { className?: string }) {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState<MenuItem>();
  const [isCollapsed, setIsCollapsed] = useState(true);
  let { user } = useAuth();

  const { _t: t } = useBetterTranslate('main-nav');
  const _t = React.useMemo(() => t, [t]);

  const localPrefs = useUserPreferences();

  useEffectAsync(async () => {
    if (!user) return;

    const views = user.preferences.views;

    const getDefaultCode = (apiPref?: { codes: string[]; root: boolean }, localPref?: string) => {
      if (!apiPref) return undefined;
      if (localPref) {
        if (apiPref.root) return localPref;
        if (apiPref.codes.find((c) => c === localPref)) return localPref;
      }

      if (apiPref.codes.length > 0) return apiPref.codes[0];
      return undefined;
    };

    let menu: MenuItem[] = [];

    const locationsCode = getDefaultCode(views.locations.clients, localPrefs.clientCode);
    if (views.locations.visible && locationsCode) {
      menu.push({ icon: <Ico file={<LandPlotIco />} />, title: _t('Standort'), href: `/locations/status/${locationsCode}`, nested: [], dataCy: 'mnuLocation' });
    }

    const infrastructureCode = getDefaultCode(views.infrastructure.clients, localPrefs.clientCode);
    // console.log('infrastructureCode', infrastructureCode);
    if (views.infrastructure.visible && infrastructureCode) {
      menu.push({
        icon: <Ico file={<ChargerIco />} />,
        title: _t('Ladeinfrastruktur'),
        href: `/infrastructure/stations/${infrastructureCode}`,
        nested: [],
        dataCy: 'mnuChargingInfrastructure',
      });
    }

    const cardsCode = getDefaultCode(views.cards.clients, localPrefs.clientCode);
    if (views.cards.visible && cardsCode) {
      menu.push({ icon: <Ico file={<ChargingCardIco />} />, title: _t('Ladekarten'), href: `/charging-cards/${cardsCode}`, nested: [], dataCy: 'mnuChargingCards' });
    }

    const sessionsCode = getDefaultCode(views.sessions.clients, localPrefs.clientCode);
    if (views.sessions.visible && sessionsCode && views.sessions.prefix) {
      menu.push({
        icon: <Ico file={<ChargingRecordIco />} />,
        title: _t('Ladevorgänge'),
        href: `/${views.sessions.prefix}-charging-sessions/${sessionsCode}`,
        nested: [],
        dataCy: 'mnuChargingRecords',
      });
    }

    const loadManagementCode = getDefaultCode(views.clusters.clients, localPrefs.clientCode);
    if (views.clusters.visible && loadManagementCode) {
      const clientPreferences = await api.profile.getPreferencesForClient(loadManagementCode, {});
      if (clientPreferences.data.clusters.count === 1) {
        menu.push({
          icon: <Ico file={<LoadManagementIco />} />,
          title: _t('Lastmanagement'),
          href: `/loadmanagement/${loadManagementCode}/cluster-stations/${clientPreferences.data.clusters.defaultId}`,
          nested: [],
          dataCy: 'mnuLoadManagement',
        });
      } else {
        menu.push({
          icon: <Ico file={<LoadManagementIco />} />,
          title: _t('Lastmanagement'),
          href: `/loadmanagement/${loadManagementCode}`,
          nested: [],
          dataCy: 'mnuLoadManagement',
        });
      }
    }

    // use load mgmt for now until permission is added
    const statusLogCode = getDefaultCode(views.logs.clients, localPrefs.clientCode);
    if (views.logs.visible && statusLogCode) {
      menu.push({
        icon: <Ico file={<StatusLogIco />} />,
        title: _t('Status Log'),
        href: `/status-log/${statusLogCode}`,
        nested: [],
        dataCy: 'mnuStatusLog',
      });
    }

    const dataCyOrg = 'mnuOrganisation';
    if (views.organization.structure?.visible) {
      const orgStructureCode = getDefaultCode(views.organization.structure?.clients, localPrefs.clientCode);
      menu.push({
        icon: <Ico file={<OriganisationIco />} />,
        title: _t('Organisation'),
        href: `/organisation/structure/${orgStructureCode}`,
        nested: [],
        dataCy: dataCyOrg,
      });
    } else if (views.organization.users?.visible) {
      const orgUsersCode = getDefaultCode(views.organization.users?.clients, localPrefs.clientCode);
      menu.push({
        icon: <Ico file={<OriganisationIco />} />,
        title: _t('Organisation'),
        href: `/organisation/users/${orgUsersCode}`,
        nested: [],
        dataCy: dataCyOrg,
      });
    }

    if (views.administration.clients?.visible || views.administration.roles?.visible || views.administration.users?.visible) {
      const dataCyAdmin = 'mnuAdministration';
      const clientsCode = getDefaultCode(views.administration.clients?.affiliates, localPrefs.affiliateCode);
      const rolesCode = getDefaultCode(views.administration.roles?.affiliates, localPrefs.affiliateCode);
      const usersCode = getDefaultCode(views.administration.users?.affiliates, localPrefs.affiliateCode);
      if (views.administration.clients && clientsCode) {
        menu.push({
          icon: <Ico file={<AdminIco />} />,
          title: _t('Administration'),
          href: `/administration/clients/${clientsCode}`,
          nested: [],
          dataCy: dataCyAdmin,
        });
      } else if (views.administration.roles) {
        menu.push({
          icon: <Ico file={<AdminIco />} />,
          title: _t('Administration'),
          href: `/administration/roles/${rolesCode}`,
          nested: [],
          dataCy: dataCyAdmin,
        });
      } else if (views.administration.users) {
        menu.push({
          icon: <Ico file={<AdminIco />} />,
          title: _t('Administration'),
          href: `/administration/users/${usersCode}`,
          nested: [],
          dataCy: dataCyAdmin,
        });
      }
    }

    if (views.system.affiliates || views.system.users) {
      if (views.system.affiliates) {
        menu.push({
          icon: <Ico file={<AdminIco />} />,
          title: _t('Super Administration'),
          href: '/system/affiliates',
          nested: [],
        });
      } else if (views.system.affiliates) {
        menu.push({
          icon: <Ico file={<AdminIco />} />,
          title: _t('Super Administration'),
          href: '/system/users',
          nested: [],
        });
      }
    }

    if (views.operations.visible) {
      menu.push({
        icon: <Ico file={<AdminIco />} />,
        title: _t('Operations'),
        href: `/operations`,
        nested: [],
      });
    }

    if (views.dev?.translations || views.dev?.versions) {
      menu.push({
        icon: <Ico file={<AdminIco />} />,
        title: _t('Development'),
        href: '/dev',
        nested: [],
        dataCy: 'mnuDevelopment',
      });
    }

    setMenuItems(menu);

    const sortedItems = [...menu].sort((a, b) => (a.href.length < b.href.length ? 1 : -1));
    const potentiallySelected = sortedItems.find((item) => {
      const parents = item.href.split('/').filter((h) => !!h);
      if (parents.length <= 0) return false;
      const parent = `/${parents[0]}`;
      return (pathname || '').startsWith(parent);
    });
    if (potentiallySelected) {
      setSelectedItem(potentiallySelected);
    }
  }, [localPrefs.clientCode, localPrefs.affiliateCode, _t, pathname]);

  return (
    <Box className={classNames(styles.root, ifTrue(styles.isCollapsed, isCollapsed))} kind={'vflex'}>
      <Box pad={['700', '400', '600', '400']} kind={'vflex'} justify='space-between' h='100%' flexGrow='1'>
        <Box kind={'vflex'} gap='m'>
          {menuItems.map((item, i) => {
            return (
              <Link key={i} to={item.href} data-cy={item.dataCy}>
                <Box
                  className={classNames(styles.item, ifTrue(styles.selected, item === selectedItem))}
                  kind={'hflex'}
                  fw='500'
                  gap='s'
                  pad={['300', '400']}
                  fs={'s'}
                  fg='primary-white'
                >
                  <Box kind={'hflex'} align='center' justify='center' className={styles.ico}>
                    {item.icon}
                  </Box>
                  <Box className={styles.text}>
                    <span>{item.title}</span>
                  </Box>
                </Box>
              </Link>
            );
          })}
        </Box>
        <Box kind={'hflex'} align='center' justify='center'>
          <Box className={styles.collapseBtn} onClick={() => setIsCollapsed((current) => !current)}>
            <Ico file={<ArrowClosePanelIco />} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
