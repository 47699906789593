import { useCallback, useEffect, useMemo, useState } from 'react';
import { Item, Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import { ReactComponent as WarnIcon } from '../../assets/warning.svg';
import { HighlightBox } from '../../components-v2/highlight-box';
import Ico from '../../components-v2/ico';
import {
  CloseFunc,
  ModalConfirmStep,
  ModalFailedStep,
  ModalPartialStep,
  ModalPendingStep,
  ModalSuccessStep,
  MultiStepModal,
  useMultiStep,
} from '../../components-v2/modal/modal.multi-step';
import Box from '../../components-v2/utils';
import RadioButton from '../../components/input-radio-button';
import { translate } from '../../i18n';
import { ChangeAvailabilityResult, ChangeAvailabilityTypes } from '../../services/api-client/csp-api';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './station-remote-actions.module.scss';

export interface Station {
  chargeBoxId: string;
  domainStatus: 'online' | 'offline' | 'failure' | 'notInOperation';
  // hasChargingConnectors: boolean;
  can: { remoteReset: boolean; remoteChangeAvailability: boolean; goToSessions: boolean };
}

export type StationRemoteAction = 'reset' | 'changeAvailability';

export type StationRemoteActionProps = {
  refreshRequested: () => Promise<void>;

  reset: {
    do: (station: Station) => Promise<boolean>;
  };
  changeAvailability: {
    do: (
      station: Station,
      newVal: ChangeAvailabilityTypes
    ) => Promise<{
      changeAvailabilityStatus: ChangeAvailabilityResult;
      connectors: number[];
    }>;
  };
};

type RemoteActionContextItem = {
  action: () => void;
  title: string;
};

function createStationRemoteActionList(station: Station, trigger: (act: StationRemoteAction) => void): RemoteActionContextItem[] {
  const actions: RemoteActionContextItem[] = [];
  const _t = translate('station-remote-actions');

  if ((station.domainStatus === 'online' || station.domainStatus === 'failure') && station.can.remoteReset) {
    actions.push({
      action: () => trigger('reset'),
      title: _t('Station neu starten'),
    });
  }

  if ((station.domainStatus === 'online' || station.domainStatus === 'failure') && station.can.remoteChangeAvailability) {
    actions.push({
      action: () => trigger('changeAvailability'),
      title: _t('Ladepunkte ent-/sperren'),
    });
  }

  return actions;
}

export function useChangeAvailabilityAction(props: StationRemoteActionProps) {
  const { _t } = useBetterTranslate('station-remote-actions');
  const title = _t('Change Availability');
  const [availabilityMode, setAvailabilityMode] = useState<ChangeAvailabilityTypes>(ChangeAvailabilityTypes.Operative);
  const [failedConnectorMsg, setFailedConnectorMsg] = useState('');
  const end = (close: CloseFunc<any, any>, result: any) => {
    return () => {
      setAvailabilityMode(ChangeAvailabilityTypes.Operative);
      setFailedConnectorMsg('');
      close(result);
      props.refreshRequested();
    };
  };
  const process = async (station: Station) => {
    popup.goTo('pending');
    try {
      const result = await props.changeAvailability.do(station, availabilityMode);
      if (result.changeAvailabilityStatus === ChangeAvailabilityResult.Success) {
        popup.goTo('success');
      } else if (result.changeAvailabilityStatus === ChangeAvailabilityResult.Partial) {
        const msg = _t(`Ein Fehler wurde von Konektor(en) {{connectors}} zurückgegeben`, { connectors: (result.connectors || []).join(', ') });
        setFailedConnectorMsg(msg);
        popup.goTo('partial');
      } else {
        popup.goTo('failed');
      }
    } catch (err) {
      popup.goTo('failed');
    }
  };
  const [popup, popupProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: Station) => {
        return (
          <ModalConfirmStep title={title} cancel={{ onClick: end(close, false) }} accept={{ text: _t('Confirm'), onClick: () => process(station) }}>
            <Box kind={'vflex'} gap='l' align='center'>
              <Box txtAlign='center'>{_t('Sind Sie sicher, dass Sie den/die Ladepunkt(e) ent-/sperren wollen?')}</Box>
              <Box kind={'hflex'} gap='xl'>
                <RadioButton
                  disabled={false}
                  label={_t('entsperren')}
                  isSelected={availabilityMode === 'Operative'}
                  onChange={() => setAvailabilityMode(ChangeAvailabilityTypes.Operative)}
                />
                <RadioButton
                  disabled={false}
                  label={_t('sperren')}
                  isSelected={availabilityMode === 'Inoperative'}
                  onChange={() => {
                    setAvailabilityMode(ChangeAvailabilityTypes.Inoperative);
                  }}
                />
              </Box>
              <HighlightBox>
                <Box kind={'hflex'} gap='s' align='center'>
                  <Ico size='24px' file={<WarnIcon />} stroke='brown-gold-metallic' />
                  <Box fg='brown-gold-metallic'>{_t('Aktive Ladevorgänge, werden durch diese Aktion abgebrochen.')}</Box>
                </Box>
              </HighlightBox>
            </Box>
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={title} cancel={false} accept={false}>
            <Box txtAlign='center'>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      partial: (close) => {
        return (
          <ModalPartialStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>
              {availabilityMode === ChangeAvailabilityTypes.Inoperative ? _t('Ladepunkt(e) wurde(n) teilweise gesperrt') : _t('Ladepunkt(e) wurde(n) teilweise entsperrt')}
            </Box>
            {failedConnectorMsg && <Box txtAlign='center'>{failedConnectorMsg}</Box>}
          </ModalPartialStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }}>
            <Box txtAlign='center'>{availabilityMode === ChangeAvailabilityTypes.Inoperative ? _t('Ladepunkt(e) wurde(n) gesperrt') : _t('Ladepunkt(e) wurde(n) entsperrt')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep title={title} accept={{ onClick: end(close, true), text: _t('Close') }} cancel={false}>
            <Box>{_t('Aktion konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { popup, popupProps };
}

export function useResetAction(props: StationRemoteActionProps) {
  const { _t } = useBetterTranslate('station-remote-actions');
  const [reset, resetProps] = useMultiStep({
    className: styles.multiStepPopup,
    steps: {
      confirm: (close, station: Station) => {
        return (
          <ModalConfirmStep
            title={_t('Station neu starten')}
            cancel={{ onClick: () => close(false) }}
            accept={{
              text: _t('Confirm'),
              onClick: async () => {
                reset.goTo('pending');
                try {
                  await props.reset.do(station);
                  reset.goTo('success');
                } catch (err) {
                  console.error('remote action failed', err);
                  reset.goTo('failed');
                }
              },
            }}
          >
            <Box kind={'vflex'} gap='l' align='center'>
              <Box>{_t('Sind Sie sicher, dass Sie die Station neu starten wollen?')}</Box>
              <HighlightBox>
                <Box kind={'hflex'} gap='s' align='center'>
                  <Ico size='24px' file={<WarnIcon />} stroke='brown-gold-metallic' />
                  <Box fg='brown-gold-metallic'>{_t('An dieser Station ist ein Ladevorgang aktiv, der durch den Neustart abgebrochen wird.')}</Box>
                </Box>
              </HighlightBox>
            </Box>
          </ModalConfirmStep>
        );
      },
      pending: (close) => {
        return (
          <ModalPendingStep title={_t('Station neu starten')} cancel={false} accept={false}>
            <Box>{_t('Bitte warten')}</Box>
          </ModalPendingStep>
        );
      },
      success: (close) => {
        return (
          <ModalSuccessStep
            title={_t('Station neu starten')}
            accept={{
              onClick: () => {
                close(true);
                props.refreshRequested();
              },
              text: _t('Close'),
            }}
          >
            <Box>{_t('Der Befehl wurde erfolgreich an die Station gesendet')}</Box>
          </ModalSuccessStep>
        );
      },
      failed: (close) => {
        return (
          <ModalFailedStep
            title={_t('Station neu starten')}
            accept={{
              onClick: () => {
                close(false);
                props.refreshRequested();
              },
              text: _t('Close'),
            }}
            cancel={false}
          >
            <Box>{_t('Neustart konnte nicht durchgeführt werden')}</Box>
          </ModalFailedStep>
        );
      },
    },
  });

  return { reset, resetProps };
}

export function useStationRemoteActions(props: StationRemoteActionProps) {
  const [station, setStation] = useState<Station | undefined>();
  const [activeAction, setActiveAction] = useState<StationRemoteAction | undefined>();

  // prevent rerenders, drawback: props cannot change from caller
  // should be fine, if not need to consider it
  // is a bit complicated because props use a lot references instead of value types
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frozenProps = useMemo(() => props, []);

  const done = useCallback(() => {
    setStation(undefined);
    setActiveAction(undefined);
  }, []);

  const { show: showContextMenu } = useContextMenu({
    id: 'remote-action-station',
  });

  const contextMenuItems = useMemo((): RemoteActionContextItem[] => {
    if (!station) return [];
    const actions = createStationRemoteActionList(station, setActiveAction);
    return actions;
  }, [station, setActiveAction]);

  const refreshRequested = props.refreshRequested; // this should not be frozen
  const stationRemoteActionsProps = useMemo(() => {
    return { ...frozenProps, refreshRequested: refreshRequested, activeAction, done, contextMenuItems, station, actionExecuted: props.refreshRequested };
  }, [refreshRequested, frozenProps, activeAction, done, contextMenuItems, station, props.refreshRequested]);

  const hasRemoteActions = useCallback(
    (station: Station) => {
      const actions = createStationRemoteActionList(station, setActiveAction);
      return actions.length > 0;
    },
    [setActiveAction]
  );

  const showStationRemoteActionContextMenu = useCallback(
    (ev: TriggerEvent, station: Station) => {
      setStation(station);
      showContextMenu(ev);
    },
    [setStation, showContextMenu]
  );

  return { stationRemoteActionsProps, showStationRemoteActionContextMenu, hasStationRemoteActions: hasRemoteActions };
}

export default function StationRemoteActions(
  props: StationRemoteActionProps & {
    actionExecuted: () => Promise<void>;
    done: () => void;
    activeAction: StationRemoteAction | undefined;
    contextMenuItems: RemoteActionContextItem[];
    station?: Station;
  }
) {
  const { reset, resetProps } = useResetAction(props);
  const { popup: changeAvailability, popupProps: changeAvailabilityProps } = useChangeAvailabilityAction(props);

  const { station, activeAction, done } = props;
  useEffect(() => {
    if (!station) return;
    if (!activeAction) return;

    if (activeAction === 'reset' && !reset.isOpen()) {
      reset.show('confirm', station).then(() => done());
    } else if (activeAction === 'changeAvailability' && !changeAvailability.isOpen()) {
      changeAvailability.show('confirm', station).then(() => done());
    }
  }, [reset, station, activeAction, changeAvailability, done]);

  return (
    <>
      <MultiStepModal {...resetProps} />
      <MultiStepModal {...changeAvailabilityProps} />

      <Menu id={`remote-action-station`}>
        {props.station &&
          props.contextMenuItems.length > 0 &&
          props.contextMenuItems.map((item, idx) => {
            return (
              <Item key={idx} onClick={() => item.action()}>
                {item.title}
              </Item>
            );
          })}
      </Menu>
    </>
  );
}
